import { RiQuestionLine, RiUserLine } from '@remixicon/react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'

import { DEFAULT_USE_CONTACT_US_CTA } from '@/constants/env'

import { Agent } from '@/types'

interface InputFooterProps {
  userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }
  setUserSettings: (userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }) => void
  adminChatbot?: boolean
  agent: Agent
}

const InputFooter: React.FC<InputFooterProps> = ({ adminChatbot, agent }) => {
  const { t } = useTranslation()
  const { toggleModal } = useModalsState()

  return (
    <div className='flex h-6 w-full max-w-screen-md items-center justify-end px-1'>
      <div className='flex items-center gap-3'>
        <div className='flex items-center gap-0.5 truncate whitespace-nowrap text-xs text-on-surface/80 dark:text-dark-on-surface/80'>
          <RiUserLine className='size-4' />
          {agent.name}
        </div>
        {/* <Popover
          placement='topRight'
          content={
            <ChatbotSettings
              userSettings={userSettings}
              setUserSettings={setUserSettings}
              adminChatbot={adminChatbot}
            />
          }
        >
          <div
            ref={userTourRef3}
            className='flex cursor-pointer items-center gap-0.5 text-xs text-on-surface/80 hover:text-on-surface/60 dark:text-dark-on-surface/80 hover:dark:text-dark-on-surface/60'
          >
            <RiSettings3Line className='size-4' />
            <span className='hidden sm:block'>{t('popover-title')}</span>
          </div>
        </Popover> */}

        {DEFAULT_USE_CONTACT_US_CTA && !adminChatbot && (
          <div
            className='flex cursor-pointer items-center gap-0.5 text-xs text-on-surface/80 hover:text-on-surface/60 dark:text-dark-on-surface/80 hover:dark:text-dark-on-surface/60'
            onClick={() => toggleModal('contactUs')}
          >
            <RiQuestionLine className='size-4' />
            <span className='hidden sm:block'>{t('help')}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default InputFooter
