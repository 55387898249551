'use client'

import { useSearchParams } from 'next/navigation'
import { createContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useTourState from '@/hooks/context/useTourState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'
import useConversation from '@/hooks/useConversation'
import useSettings from '@/hooks/useSettings'

import QuestionInput from '@/components/Chatbot/QuestionInput'

import { DEFAULT_USE_CONTACT_US_CTA } from '@/constants/env'
import {
  getAnswer,
  mapConversation,
  stopAnswering,
  uploadDocuments,
} from '@/service/Chatbot'
import { cn } from '@/utils/clsx'

import Answers from './Chatbot/Output/Answers'
import ContactUsModal from './Chatbot/Output/ContactUsModal'
import UnderMaintenance from './UnderMaintenance'

import { Swarm } from '@/types'
import { IQuestion } from '@/types/chatbot'

export const IndexPageContext = createContext(false)

interface ChatbotProps {
  indexPage?: boolean
  hideHeader?: boolean
  adminChatbot?: boolean
  swarm?: Swarm
  className?: string
}

const Chatbot: React.FC<ChatbotProps> = ({
  indexPage,
  hideHeader,
  adminChatbot,
  swarm,
  className,
}) => {
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const { selectedConversation, mutateConversations } = useDrawerState()
  const { startTour } = useTourState()

  const { user } = useAuth()
  const { conversation, error: conversationError } =
    useConversation(selectedConversation)
  const { agents, selectedAgentAdmin, error: agentsError } = useAgents()
  const { settings, mutate } = useSettings(selectedAgentAdmin?.id ?? '')

  const [questions, setQuestions] = useState([] as IQuestion[])
  const [loading, setLoading] = useState(false)
  const [userSettings, setUserSettings] = useState<{
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }>()
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [dragging, setDragging] = useState<boolean>(false)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true)
  const answersRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (settings && !userSettings) {
      setUserSettings({
        useGpt4: settings.model === 'gpt4' || settings.model === 'gpt4o',
        useDrafting: !!settings.useDrafting,
        useStreaming: !!settings.useStreaming,
      })
    }
  }, [settings])

  useEffect(() => {
    if (user?.role === 'USER' && userSettings) {
      startTour('userTour')
    }
  }, [user, userSettings])

  // when conversation is loaded, map it to questions
  useEffect(() => {
    if (conversation && agents && !conversationError && !agentsError) {
      setQuestions(mapConversation(conversation, agents))
    }
  }, [conversation, agents])

  // get answer when all necessary data is loaded
  useEffect(() => {
    if (
      !!userSettings &&
      !!selectedAgentAdmin &&
      searchParams.get('question') &&
      !loading
    ) {
      getAnswerWrapper(true, searchParams.get('question')!)
    }
  }, [userSettings, selectedAgentAdmin, searchParams])

  const getAnswerWrapper = async (
    domain: boolean,
    question: string,
    agentId?: string,
    isCommand = false
  ) => {
    await uploadDocuments(
      fileList,
      agentId,
      t,
      setFileList,
      true,
      undefined,
      selectedConversation
    )

    try {
      await getAnswer(
        domain,
        question,
        agentId ?? selectedAgentAdmin?.id,
        questions,
        agents,
        setLoading,
        setQuestions,
        t,
        isCommand,
        user?.email,
        userSettings?.useGpt4,
        selectedConversation,
        userSettings?.useStreaming,
        userSettings?.useDrafting,
        adminChatbot,
        swarm
      )
    } catch (e) {
      console.error('Error getting answer', e)
    }

    mutateConversations()

    if (isCommand) {
      mutate()
    }
  }

  const scrollToBottom = () => {
    answersRef.current?.scrollTo(0, answersRef.current.scrollHeight)
  }

  const handleScroll = () => {
    if (
      answersRef.current &&
      answersRef.current.scrollHeight - answersRef.current.scrollTop ===
        answersRef.current.clientHeight
    ) {
      setIsScrolledToBottom(true)
    } else {
      setIsScrolledToBottom(false)
    }
  }

  useEffect(() => {
    if (answersRef.current) {
      handleScroll()
      answersRef.current?.addEventListener('scroll', handleScroll)
      return () => {
        answersRef.current?.removeEventListener('scroll', handleScroll)
      }
    }
  }, [answersRef.current])

  useEffect(() => {
    if (answersRef.current) {
      handleScroll()
    }
  }, [questions])

  if (conversationError || agentsError) {
    return <UnderMaintenance onRefreshClick={() => location.reload()} />
  }

  return (
    <IndexPageContext.Provider value={!hideHeader ? !!indexPage : false}>
      <main
        className={cn(
          'relative flex flex-col bg-background dark:bg-dark-background shadow-md',
          {
            rounded: !hideHeader,
            'sm:h-auto': !indexPage,
            '': !adminChatbot,
          }
        )}
        onDragOver={(e) => {
          e.preventDefault()
          setDragging(true)
        }}
        onDragLeave={() => setDragging(false)}
        onDragEnd={() => setDragging(false)}
        onDrop={() => setDragging(false)}
      >
        <div className={className}>
          <Answers
            questions={questions}
            getAnswer={getAnswerWrapper}
            hideHeader={hideHeader}
            adminChatbot={adminChatbot}
            isCtaOpen={DEFAULT_USE_CONTACT_US_CTA}
            onSelectedDraft={(draft) => {
              const tmpQuestions = [...questions]
              const question = tmpQuestions[tmpQuestions.length - 1]
              const message = question!.messages[question!.messages.length - 1]
              if (message?.message) {
                message.message = draft
              }
              setQuestions([...tmpQuestions])
            }}
            agents={agents}
            setQuestions={setQuestions}
            answersRef={answersRef}
          />
          {!!userSettings && (
            <QuestionInput
              userSettings={userSettings}
              setUserSettings={setUserSettings}
              getAnswer={getAnswerWrapper}
              stopAnswering={() =>
                stopAnswering(userSettings.useStreaming, setLoading)
              }
              loading={loading}
              adminChatbot={adminChatbot}
              settings={settings}
              showSuggestedQuestions={questions.length === 0}
              setFileList={setFileList}
              fileList={fileList}
              dragging={dragging}
              agent={selectedAgentAdmin}
              isChatEmpty={questions.length === 0}
              isScrolledToBottom={isScrolledToBottom}
              scrollToBottom={scrollToBottom}
            />
          )}
        </div>
      </main>

      {DEFAULT_USE_CONTACT_US_CTA && (
        <ContactUsModal
          chat={questions.flatMap(({ question, messages }) =>
            messages.map(({ message }) => {
              return { question, message }
            })
          )}
        />
      )}
    </IndexPageContext.Provider>
  )
}

export default Chatbot
